import { all, put, takeEvery } from "redux-saga/effects";
import api from "../../../utils/axios";
import {
  calcualtionSuccess,
  calcualtionFailure,
} from "../../actions/dashboard/dashboardActions";
import { getIndividualInfo } from "../../../utils/Utils";

import { ADDCALCULATIONDATA } from "../../actions/types";

function* fetchReatrialScore(action) {
  try {
    const pData = getIndividualInfo();
    let ind_id = pData.individual_id;
    let retrialApiData = yield api(process.env.REACT_APP_CMS_API).get(
      `/retrial_score/${ind_id}`
    );
    yield put(calcualtionSuccess(retrialApiData?.data?.responseBody?.data[0]));
  } catch (error) {
    yield put(calcualtionFailure(error));
  }
}

function* retrialInfoSaga() {
  yield takeEvery(ADDCALCULATIONDATA, fetchReatrialScore);
}

export default function* rootSaga() {
  yield all([retrialInfoSaga()]);
}
