import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Loader from "../common/loader";
import { DecryptData } from "../../utils/encryptedPayload";
import { local, setIndividualInfo } from "../../utils/Utils";
import api from "../../utils/axios";
import axios from "axios";

const SSORedirect = () => {
  const history = useHistory();
  let ssoLink = window?.location?.href;
  const apiCallCal = (indInfo, token, path) => {
    let calcObj = {
      income: indInfo.salary,
      userDOB: indInfo.individual_dob,
      individualId: indInfo.individual_id,
      token: token,
    };
    api(process.env.REACT_APP_CMS_API)
      .post("retrial_score/calculate-new", calcObj)
      .then((res) => history.push(path))
      .catch((err) => console.log(err.toString()));
  };
  // decryption
  const urlDecrypt = async() => {
    let decData = DecryptData(ssoLink);
    const baseUrl = process.env.REACT_APP_API_DOMAIN;


    await axios.post(`${baseUrl}/api-mdm/auth/set-session`,{token: decData.token}, { withCredentials: true})


    // local.setItem("loginToken", decData.token); //set login token in cookies from localstorage 
    local.setItem("loginToken", true);

    setIndividualInfo(decData.individualInfo);
    let redirectPage = new URL("", decData.target);
    let redirectPath = redirectPage.pathname;
    apiCallCal(decData.individualInfo, decData.token, redirectPath);
  };

  useEffect(() => {
    // eslint-disable-next-line
    urlDecrypt();
    // eslint-disable-next-line
  }, []);

  return <Loader />;
};

export default SSORedirect;
