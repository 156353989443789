import { put, takeEvery, all } from "redux-saga/effects";
import api from "../../../utils/axios";
import { ADDUSEDINVESTCALL, ADDDASHBORADCMS } from "../../actions/types";
import {
  investInsActionSuccess,
  investInsActionFailure,
  dashboardCmsSuccess,
  dashboardCmsFailure,
} from "../../actions/dashboard/dashboardActions";
import { getIndividualInfo, local } from "../../../utils/Utils";

function* fetchIndivialInvest(action) {
  try {
    let token = local.getItem("loginToken");
    let individualData = yield api(process.env.REACT_APP_API_DOMAIN, token).get(
      "/api-mdm/individualinvestments/"
    );
    yield put(investInsActionSuccess(individualData.data.data));
  } catch (error) {
    yield put(investInsActionFailure(error));
  }
}

function* fetchDashboardCms(action) {
  try {
    const pData = getIndividualInfo();
    const ind_id = pData.individual_id;
    let cmsApiData = yield api(process.env.REACT_APP_CMS_API).get(
      `dashboard/${ind_id}`
    );
    yield put(dashboardCmsSuccess(cmsApiData.data.responseBody.data));
    return true;
  } catch (error) {
    yield put(dashboardCmsFailure(error));
  }
}

function* individualInvestSaga() {
  yield takeEvery(ADDUSEDINVESTCALL, fetchIndivialInvest);
}

function* dashboardCmsSaga() {
  yield takeEvery(ADDDASHBORADCMS, fetchDashboardCms);
}

export default function* rootSaga() {
  yield all([individualInvestSaga(), dashboardCmsSaga()]);
}
