import storage from "redux-persist/lib/storage";
import CryptoJS from "crypto-js";

const SECRET_KEY =
  process.env.LOCAL_STORAGE_ENCRYPTION_DECRYPTION_KEY || "no-encryption-key";

export const encrypt = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
};

export const decrypt = (data) => {
  const bytes = CryptoJS.AES.decrypt(data, SECRET_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

const encryptedStorage = {
  async getItem(key) {
    const value = await storage.getItem(key);
    if (value) {
      return decrypt(value);
    }
    return null;
  },
  async setItem(key, value) {
    const encryptedValue = encrypt(value);
    await storage.setItem(key, encryptedValue);
  },
  async removeItem(key) {
    await storage.removeItem(key);
  },
};

export default encryptedStorage;
